<template>
  <popup
    :max-width="'sm:max-w-6xl'"
    :open="open"
    :title="'Review Candidate Assessment'"
    @cancel="close"
    z-index="z-50"
  >
    <template v-slot:icon>
      <div
        class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100"
      >
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
    </template>
    <template v-slot:content>
      <div class="h-terms overflow-auto">
        <div id="learnosity_assess"></div>
      </div>
    </template>
    <template v-slot:footer>
      <div
        class="mt-5 sm:mt-6 sm:grid sm:grid-cols-4 sm:gap-3 sm:grid-flow-row-dense"
      >
        <span
          class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-4"
        >
          <button
            :disabled="processing"
            @click.prevent="close"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Close
          </button>
        </span>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from "@/components/Popup";

export default {
  emits: ["close"],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    session: {
      type: Object,
    },
  },
  components: {
    Popup,
  },
  data() {
    return {
      processing: false,
    };
  },
  watch: {
    session(session) {
      console.log("Watch session triggered");
      if (session !== null) {
        console.log("Session received");
        console.log(session);
        this.$nextTick(() => {
          this.loadLearnosity(() => {
            window.LearnosityItems.init(this.session.learnosity_data.options);
            console.log("Learnosity initiated!");
          }, session);
        });
      } else {
        this.$nextTick(() => {
          document.getElementById("learnosity_assess").innerHTML = "";
        });
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    loadLearnosity(callback, session) {
      console.log("Loading Learnosity assets");
      callback = callback || function() {};
      if (typeof window.LearnosityItems !== "undefined") {
        callback();
      } else {
        let script = document.createElement("script");
        script.src = session.learnosity_data.script;
        script.onload = callback;
        document.head.appendChild(script);
      }
    },
  },
};
</script>
