<template>
  <layout>
    <template v-slot:contents>
      <div class="mb-4">
        <div>
          <label
            for="series"
            class="block text-sm leading-5 font-medium text-gray-700"
            >Series</label
          >
          <select
            v-model="selected_series"
            @change="seriesChanged"
            id="series"
            class="mt-1 rounded-md block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
          >
            <option value="">Please select a series</option>
            <option
              v-for="_series in series_list"
              :value="_series.code"
              :key="_series.code"
              >{{ _series.name }}</option
            >
          </select>
        </div>
        <div class="mt-4" v-if="booking_options.length">
          <label
            for="booking_options"
            class="block text-sm leading-5 font-medium text-gray-700"
            >Booking Options</label
          >
          <select
            v-model="selected_option"
            @change="optionChanged"
            id="booking_options"
            class="mt-1 rounded-md block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
          >
            <option value="">Please select a booking option</option>
            <option
              v-for="option in booking_options"
              :value="option.id"
              :key="option.id"
              >{{ option.booking_service.name }} - {{ option.name }}</option
            >
          </select>
        </div>
        <div
          v-if="loading"
          class="flex items-center justify-center h-16 border border-gray-100 bg-blue-50 rounded-md mt-4"
        >
          <loader class="w-5 text-blue-500" />
          <span class="text-blue-500 ml-2 text-xs"
            >loading data please wait</span
          >
        </div>
      </div>

      <button
        @click.prevent="generate"
        :disabled="selected_schedules.length === 0 || processing"
        type="button"
        :class="
          selected_schedules.length === 0 || processing
            ? 'bg-gray-300 cursor-default'
            : 'bg-indigo-600 hover:bg-indigo-500'
        "
        class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
      >
        <span>Generate Report</span>
        <loader v-if="processing" class="w-6 ml-4" />
      </button>

      <div class="flex flex-col mt-4">
        <div
          class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <div
            class="align-middle inline-block min-w-full shadow-sm overflow-hidden border rounded-md border-gray-100"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 tracking-wider"
                  >
                    <input
                      type="checkbox"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      @change="toggleAll"
                    />
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Assessment Schedule
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Assessment Title
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Series
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Subject Code
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Paper Code
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="
                    selected_series !== '' &&
                      selected_option !== '' &&
                      !loading &&
                      !schedules.length
                  "
                  class="bg-white"
                >
                  <td
                    colspan="20"
                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900"
                  >
                    Sorry, no results found
                  </td>
                </tr>
                <tr
                  v-for="schedule in schedules"
                  :key="schedule.id"
                  class="bg-white"
                >
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    <input
                      v-model="this.selected_schedules"
                      :value="schedule.id"
                      type="checkbox"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    {{ schedule.name }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    {{ schedule.assessment.title }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    {{ schedule.assessment.series.name }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    <span
                      class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800"
                    >
                      {{ schedule.assessment.subject.code }}
                    </span>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    <span
                      class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800"
                    >
                      {{ schedule.assessment.code }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="selected_series !== '' && selected_option !== ''"
            class="mt-8"
          >
            <pagination
              v-if="!loading"
              @paginate="paginate"
              :collection-name="'Assessments'"
              :prev-page="prevPage"
              :next-page="nextPage"
              :page-links="pageLinks"
              :to="pagination.showing.to"
              :from="pagination.showing.from"
              :of="pagination.showing.of"
              :current="pagination.current_page"
            >
            </pagination>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from "../components/Layout";
import Loader from "@/components/Loader";
import Pagination from "@/components/Pagination";
import PaginationUtils from "@/utils/PaginationUtils";
export default {
  components: {
    Pagination,
    Loader,
    Layout,
  },
  computed: {
    ...PaginationUtils.computed,
  },
  data() {
    return {
      loading: false,
      selected_series: "",
      selected_option: "",
      selected_schedules: [],
      series_list: [],
      booking_options: [],
      schedules: [],
      processing: false,
      ...PaginationUtils.data,
    };
  },
  mounted() {
    this.getSeriesList();
  },
  methods: {
    generate() {
      this.processing = true;

      this.$axios
        .post(this.$global.apiEndpoint("/schedules/femaths-report"), {
          schedules: this.selected_schedules,
        })
        .then(() => {
          this.$global.success(
            "You will receive an email when the export is ready",
            "Processing",
          );
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error);
        })
        .finally(() => {
          this.processing = false;
        });
    },
    paginate(page_number) {
      this.getAssessmentSchedules(page_number);
    },
    toggleAll(e) {
      this.selected_schedules = [];

      if (e.target.checked) {
        this.schedules.forEach(schedule =>
          this.selected_schedules.push(schedule.id),
        );
      }
    },
    getAssessmentSchedules(page_number) {
      this.schedules = [];
      this.loading = true;
      this.pagination.current_page = page_number || 1;

      this.$axios
        .post(
          this.$global.apiEndpoint(
            `/dashboard/${this.selected_series}/${this.selected_option}/assessment-schedules`,
          ),
          { page: this.pagination.current_page },
        )
        .then(response => {
          this.schedules = response.data.assessment_schedules.data;
          this.pagination = this.$global.paginationData(
            response,
            "assessment_schedules",
          );
          if (!this.schedules.length) {
            this.$global.error(
              "No schedules have been created for this series and service.",
            );
          }
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error, false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    optionChanged() {
      this.getAssessmentSchedules();
    },
    seriesChanged() {
      this.selected_option = "";
      this.booking_options = [];

      if (this.selected_series !== "") {
        this.getBookingOptions();
      }
    },
    getSeriesList() {
      this.loading = true;

      this.$axios
        .get(
          this.$global.apiEndpoint(
            "/bookings/series-list?service=feMaths&closed=1",
          ),
        )
        .then(response => {
          this.series_list = response.data.series;
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getBookingOptions() {
      this.loading = true;

      this.$axios
        .post(
          this.$global.apiEndpoint(
            `/dashboard/${this.selected_series}/booking-options?service=feMaths&closed=1`,
          ),
        )
        .then(response => {
          this.booking_options = response.data.booking_options;

          if (this.booking_options.length === 1) {
            this.selected_option = this.booking_options[0].id;
            this.optionChanged();
          }
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
