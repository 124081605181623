<template>
  <layout>
    <template v-slot:contents>
      <booking-import-selection />
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import BookingImportSelection from "@/components/BookingImportSelection";

export default {
  name: "Dashboard",
  beforeRouteEnter(to, from, next) {
    window.bus.$gates.home(to, from, next);
  },
  components: {
    Layout,
    BookingImportSelection,
  },
};
</script>
