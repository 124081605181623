export default {
  data: {
    app: null,
  },

  /**
   * App
   * @param app
   */
  app(app) {
    return (this.data.app = app);
  },

  /**
   * Login
   * @param to
   * @param from
   * @param next
   */
  login(to, from, next) {
    this.data.app.$global.pageIsLoading();
    if (this.data.app.$store.state.user !== null) {
      if (to.name === "Login") {
        next("/");
      } else {
        this.redirect(to, from, next);
      }
    } else {
      next(() => this.data.app.$global.pageIsNotLoading());
    }
  },

  /**
   * Forgotten Password
   * @param to
   * @param from
   * @param next
   */
  forgottenPassword(to, from, next) {
    next(() => this.data.app.$global.pageIsNotLoading());
  },

  /**
   * Reset Password
   * @param to
   * @param from
   * @param next
   */
  resetPassword(to, from, next) {
    next(() => this.data.app.$global.pageIsNotLoading());
  },

  /**
   * Register
   * @param to
   * @param from
   * @param next
   */
  register(to, from, next) {
    this.data.app.$global.pageIsLoading();
    if (this.data.app.$store.state.user !== null) {
      this.redirect(to, from, next);
    } else {
      next(() => this.data.app.$global.pageIsNotLoading());
    }
  },

  /**
   * Complete Registration
   * @param to
   * @param from
   * @param next
   * @param callback
   */
  completeRegistration(to, from, next, callback) {
    this.data.app.$global.pageIsLoading();
    if (this.data.app.$store.state.user !== null) {
      if (to.name === "CompleteRegistration") {
        next("/");
      } else {
        this.redirect(to, from, next);
      }
    } else {
      this.data.app.$axios
        .post(
          this.data.app.$global.endpoint(
            `/api/${this.data.app.$APIVersion}/profile/${to.params.ref}/complete-registration`,
          ),
        )
        .then(response => {
          callback(response);
          next(() => this.data.app.$global.pageIsNotLoading());
        })
        .catch(() => {
          this.redirect(to, from, next, "/token-expired");
        });
    }
  },

  /**
   * Home
   * @param to
   * @param from
   * @param next
   */
  home(to, from, next) {
    this.data.app.$global.pageIsLoading();
    if (this.data.app.$store.state.user === null) {
      this.redirect(to, from, next, "login");
    } else {
      next(() => this.data.app.$global.pageIsNotLoading());
    }
  },

  /**
   * Token Expired
   * @param to
   * @param from
   * @param next
   */
  tokenExpired(to, from, next) {
    next(() => this.data.app.$global.pageIsNotLoading());
  },

  /**
   * Redirect to said path as long as the path is not the same as the current path.
   * (Vue Router doesn't like it if it is)
   * @param to
   * @param from
   * @param next
   * @param toPath
   */
  redirect(to, from, next, toPath) {
    toPath = toPath || "/";
    if (toPath !== to.path && toPath !== from.path) {
      next(toPath);
    } else {
      this.data.app.$global.pageIsNotLoading();
    }
  },
};
