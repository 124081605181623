<template>
  <popup
    :open="open"
    :title="'Extra Time Allocation'"
    @cancel="close"
    @state-change="stateChange"
  >
    <template v-slot:icon>
      <div
        class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100"
      >
        <svg
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      </div>
    </template>
    <template v-slot:content>
      <div v-if="booking" class="text-left mt-8">
        <label
          for="percentage"
          class="block text-sm font-medium leading-5 text-gray-700"
          >Percentage ({{
            booking.group.assessment_schedule.max_time
          }}
          minutes)</label
        >
        <div class="mt-1 relative rounded-md shadow-sm">
          <div
            class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          >
            <svg
              class="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
              />
            </svg>
          </div>
          <input
            v-model="percentage"
            id="percentage"
            :disabled="processing"
            type="number"
            class="block w-full pl-10 sm:text-sm sm:leading-5 border-gray-300 rounded-md"
            placeholder="5"
          />
        </div>
      </div>
      <div class="text-left mt-6">
        <label
          for="minutes"
          class="block text-sm font-medium leading-5 text-gray-700"
          >Extra time in minutes</label
        >
        <div class="mt-1 relative rounded-md shadow-sm">
          <div
            class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          >
            <svg
              class="h-5 w-5 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
          <input
            readonly
            v-model="minutes"
            id="minutes"
            disabled
            type="number"
            class="bg-gray-100 block w-full pl-10 sm:text-sm sm:leading-5 border-gray-300 rounded-md"
            placeholder="60"
          />
        </div>
      </div>
      <div v-if="is_highstake" class="mt-4">
        <div class="rounded-md bg-yellow-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg
                class="h-5 w-5 text-yellow-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              You must have gained approval for extra time through the Pearson
              Access Arrangements Online (PAAO) portal. Please input the PAAO
              reference in the field below.
            </div>
          </div>
        </div>
        <div class="text-left mt-6">
          <label
            for="paaoRef"
            class="block text-sm font-medium leading-5 text-gray-700"
            >PAAO Reference</label
          >
          <div class="mt-1 relative rounded-md shadow-sm">
            <div
              class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
            >
              <svg
                class="h-5 w-5 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <input
              v-model="paaoRef"
              id="paaoRef"
              :disabled="processing"
              type="number"
              class="block w-full pl-10 sm:text-sm sm:leading-5 border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div
        class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
      >
        <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
          <button
            :disabled="processing"
            @click.prevent="setTime"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            <loader v-if="processing" class="w-5 h-5"></loader>
            <span v-else>Set extra time</span>
          </button>
        </span>
        <span
          class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1"
        >
          <button
            :disabled="processing"
            @click.prevent="close"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Close
          </button>
        </span>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from "@/components/Popup";
import Loader from "@/components/Loader";

export default {
  emits: ["updated", "close"],
  props: {
    booking: {
      type: Object,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Popup,
    Loader,
  },
  data() {
    return {
      processing: false,
      percentage: undefined,
      minutes: undefined,
      paaoRef: "",
    };
  },
  computed: {
    is_highstake() {
      return (
        this.booking &&
        this.booking.group.assessment_schedule.booking_option.booking_service
          .service_type === "highstake"
      );
    },
  },
  watch: {
    percentage(newVal) {
      if (this.booking) {
        this.minutes = Math.ceil(
          this.booking.group.assessment_schedule.max_time * (0 + newVal / 100),
        );
      }
    },
  },
  methods: {
    setTime() {
      this.processing = true;

      let data = {
        minutes: this.minutes,
      };

      if (this.is_highstake) {
        data.paaoRef = this.paaoRef;
      }

      this.$axios
        .patch(
          this.$global.apiEndpoint(`/bookings/${this.booking.id}/extra-time`),
          data,
        )
        .then(response => {
          this.$emit("updated", data);
          this.processing = false;
          this.minutes = undefined;
          this.$global.success(response.data);
          this.close();
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error);
          this.processing = false;
        });
    },
    stateChange(state) {
      this.percentage = 0;
      if (state) {
        this.$nextTick(() => {
          this.minutes = this.booking.extra_time;
        });
      }
    },
    close() {
      this.percentage = 0;
      this.paaoRef = "";

      this.$emit("close");
    },
  },
};
</script>
