<template>
  <popup
    :max-width="'sm:max-w-6xl'"
    :open="open"
    :title="'Review Candidate Assessment'"
    @cancel="close"
    z-index="z-50"
    height="h-90vh"
    :hide_icon="true"
    :hide_title="true"
  >
    <template v-slot:content>
      <iframe
        v-if="url"
        :src="url"
        class="w-full absolute inset-x-0 top-0 bottom-16 h-90perc"
      ></iframe>
      <div
        v-else
        class="flex items-center justify-center h-16 border border-gray-100 bg-blue-50 rounded-md mt-4"
      >
        <loader class="w-5 text-blue-500"></loader>
        <span class="text-blue-500 ml-2 text-xs">loading data please wait</span>
      </div>
    </template>
    <template v-slot:footer>
      <div
        class="mt-5 sm:mt-6 sm:grid sm:grid-cols-4 sm:gap-3 sm:grid-flow-row-dense absolute left-0 bottom-2.5 right-2.5"
      >
        <span
          class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-4"
        >
          <button
            :disabled="processing"
            @click.prevent="close"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Close
          </button>
        </span>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from "@/components/Popup";
import Loader from "@/components/Loader";

export default {
  emits: ["close"],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    session: {
      type: Object,
    },
  },
  components: {
    Loader,
    Popup,
  },
  data() {
    return {
      processing: false,
      url: null,
    };
  },
  watch: {
    open() {
      if (this.open) {
        this.getUrl();
      }
    },
  },
  methods: {
    close() {
      this.url = null;
      this.$emit("close");
    },
    getUrl() {
      this.$axios
        .post(
          this.$global.apiEndpoint(`/bookings/${this.session.id}/paper-pdf`),
          {},
          {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
            },
          },
        )
        .then(response => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          this.url = URL.createObjectURL(blob);
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error, false);
        });
    },
  },
};
</script>
