<template>
  <layout>
    <template v-slot:contents>
      <div
        v-if="translation()"
        class="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
      >
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <h1
            class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900"
          >
            {{ translation().headings.sign_in_account }}
          </h1>
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <form
            ref="form"
            :class="rtl('text-right')"
            class="lg:bg-white py-8 px-4 lg:shadow sm:rounded-lg sm:px-10"
            role="form"
          >
            <div v-if="errors.global" class="text-red-500 text-sm mb-6">
              {{ errors.global }}
            </div>
            <div class="mt-6">
              <label
                aria-hidden="true"
                class="block text-sm font-medium leading-5 text-gray-700"
                for="email"
              >
                {{ translation().labels.email }}
              </label>
              <div v-if="errors.email" class="text-red-500 text-sm">
                {{ errors.email }}
              </div>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  id="email"
                  v-model="email"
                  :class="rtl('text-right')"
                  :disabled="processing"
                  aria-label="Email address"
                  aria-required="true"
                  autocomplete="email"
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:ring-2 focus:ring-indigo-300"
                  required
                  type="email"
                />
              </div>
            </div>
            <div class="mt-6">
              <label
                aria-hidden="true"
                class="block text-sm font-medium leading-5 text-gray-700"
                for="password"
              >
                {{ translation().labels.password }}
              </label>
              <div v-if="errors.password" class="text-red-500 text-sm">
                {{ errors.password }}
              </div>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  id="password"
                  v-model="password"
                  :class="rtl('text-right')"
                  :disabled="processing"
                  aria-label="Account password"
                  aria-required="true"
                  autocomplete="current-password"
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:ring-2 focus:ring-indigo-300"
                  required
                  type="password"
                />
              </div>
            </div>
            <div class="mt-6 flex items-center justify-between">
              <div class="text-sm leading-5 ml-auto">
                <a
                  :class="{ 'pointer-events-none select-none': processing }"
                  class="underline font-medium text-indigo-800 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150 focus:ring-2 focus:ring-indigo-800 focus:no-underline rounded"
                  href="#"
                  role="link"
                  test-ref="forgotten-password"
                  @click.prevent="forgotten"
                >
                  {{ translation().buttons_links.forgotten_password }}
                </a>
              </div>
            </div>
            <div class="mt-6">
              <span class="block w-full rounded-md shadow-sm">
                <button
                  :disabled="processing"
                  class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out focus:ring-2 focus:ring-indigo-300"
                  role="button"
                  test-ref="sign-in"
                  type="submit"
                  @click.prevent="login"
                >
                  <loader v-if="processing" class="w-5 h-5"></loader>
                  <span v-else>{{ translation().buttons_links.sign_in }}</span>
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import Loader from "@/components/Loader";

export default {
  beforeRouteEnter(to, from, next) {
    window.bus.$gates.login(to, from, next);
  },
  components: {
    Layout,
    Loader,
  },
  data() {
    return {
      processing: false,
      email: "",
      password: "",
      errors: {
        global: "",
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      this.clearErrors();
      if (!this.$refs.form.checkValidity()) {
        return this.$refs.form.reportValidity();
      }
      this.processing = true;
      this.$global.setXSRFToken(() => {
        this.$axios
          .post(this.$global.endpoint("/sanctum/users/token"), this.payload())
          .then(response => {
            this.$store.commit("setUser", response.data.user);
            this.$store.commit("setToken", response.data.token);
            this.$store.commit("setLoggedInAtTime", this.$moment().format());
            this.$axios.defaults.headers.common["Authorization"] =
              "Bearer " + this.$store.state.token;
            this.processing = false;
            this.$router.push("/");
          })
          .catch(error => {
            this.$global.globalAXIOSErrorHandler(error, this);
            this.processing = false;
          });
      });
    },
    payload() {
      return {
        locale: this.$store.state.translatables.locale,
        email: this.email,
        password: this.password,
        device_name: window.WURFL
          ? window.WURFL.complete_device_name
          : "undefined",
      };
    },
    forgotten() {
      this.$router.push("/forgotten-password");
    },
    clearErrors() {
      this.errors = {
        global: "",
        email: "",
        password: "",
      };
    },
  },
};
</script>
