<template>
  <layout>
    <template v-slot:contents>
      <div
        class="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
      >
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <h1
            class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900"
          >
            Token Expired
          </h1>
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div class="prose">
            <p>
              It appears that the token provided in the URL has expired. This
              usually happens when the registration process has already been
              complete, but the user has tried to complete the registration
              again.
            </p>
            <p>
              If you have completed your registration but your credentials are
              not working, try resetting your password or please contact
              support.
            </p>
          </div>
          <div class="mt-6">
            <span class="block w-full rounded-md shadow-sm">
              <a
                href="#"
                @click.prevent="login"
                class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out focus:ring-2 focus:ring-indigo-300"
                role="button"
                test-ref="sign-in"
                type="submit"
              >
                <span>{{ translation.buttons_links.sign_in }}</span>
              </a>
            </span>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";

export default {
  beforeRouteEnter(to, from, next) {
    window.bus.$gates.tokenExpired(to, from, next);
  },
  components: {
    Layout,
  },
  methods: {
    login() {
      this.$router.push("/login");
    },
  },
};
</script>
