import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/Auth/Login";
import ForgottenPassword from "@/views/Auth/ForgottenPassword";
import CompleteRegistration from "@/views/Auth/CompleteRegistration";
import TokenExpired from "@/views/Auth/TokenExpired";
import Dashboard from "@/views/Dashboard";
import Bookings from "@/views/Bookings";
import ScheduleGroups from "@/views/ScheduleGroups";
import Invigilators from "@/views/Invigilators";
import ResetPassword from "@/views/Auth/ResetPassword";
import Scripts from "@/views/Scripts";
import Marks from "@/views/Marks";
import FeMathsReport from "@/views/FeMathsReport";

const routes = [
  { path: "/", name: "Dashboard", component: Dashboard },
  { path: "/bookings", name: "Bookings", component: Bookings },
  {
    path: "/schedule-groups",
    name: "ScheduleGroups",
    component: ScheduleGroups,
  },
  { path: "/invigilators", name: "Invigilators", component: Invigilators },
  { path: "/login", name: "Login", component: Login },
  { path: "/token-expired", name: "TokenExpired", component: TokenExpired },
  {
    path: "/profile/:ref",
    name: "CompleteRegistration",
    component: CompleteRegistration,
  },
  {
    path: "/forgotten-password",
    name: "ForgottenPassword",
    component: ForgottenPassword,
  },
  {
    path: "/password/reset/:token",
    name: "password-reset",
    component: ResetPassword,
  },
  { path: "/scripts", name: "Scripts", component: Scripts },
  { path: "/mark-submission", name: "Marks", component: Marks },
  { path: "/fe-maths-report", name: "FeMathsReport", component: FeMathsReport },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
