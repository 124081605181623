<template>
  <div>
    <layout>
      <template v-slot:contents>
        <div
          class="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
        >
          <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h1
              class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900"
            >
              {{ translation().headings.complete_registration }}
            </h1>
          </div>
          <form ref="form" class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div
              :class="rtl('text-right')"
              class="lg:bg-white py-8 px-4 lg:shadow sm:rounded-lg sm:px-10"
            >
              <div v-if="errors.global" class="text-red-500 text-sm mb-6">
                {{ errors.global }}
              </div>
              <div class="mt-6">
                <label
                  aria-hidden="true"
                  class="block text-sm font-medium leading-5 text-gray-700"
                  for="email"
                >
                  {{ translation().labels.email }}
                </label>
                <div v-if="errors.email" class="text-red-500 text-sm">
                  {{ errors.email }}
                </div>
                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    id="email"
                    v-model="email"
                    :class="rtl('text-right')"
                    :disabled="processing"
                    aria-label="Email address"
                    aria-required="true"
                    autocomplete="email"
                    class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    required
                    type="email"
                  />
                </div>
              </div>
              <div class="mt-6">
                <label
                  aria-hidden="true"
                  class="block text-sm font-medium leading-5 text-gray-700"
                  for="email"
                >
                  {{ translation().labels.email_confirmation }}
                </label>
                <div
                  v-if="errors.email_confirmation"
                  class="text-red-500 text-sm"
                >
                  {{ errors.email_confirmation }}
                </div>
                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    id="email_confirmation"
                    v-model="email_confirmation"
                    :class="rtl('text-right')"
                    :disabled="processing"
                    aria-label="Email confirmation"
                    aria-required="true"
                    autocomplete="email"
                    class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    required
                    type="email"
                  />
                </div>
              </div>
              <div class="mt-6">
                <label
                  class="block text-sm font-medium leading-5 text-gray-700"
                  for="password"
                >
                  {{ translation().labels.password }}
                </label>
                <div v-if="errors.password" class="text-red-500 text-sm">
                  {{ errors.password }}
                </div>
                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    id="password"
                    v-model="password"
                    :class="rtl('text-right')"
                    :disabled="processing"
                    aria-required="true"
                    autocomplete="new-password"
                    class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    required
                    type="password"
                  />
                </div>
              </div>
              <div class="mt-6">
                <label
                  class="block text-sm font-medium leading-5 text-gray-700"
                  for="password_confirmation"
                >
                  {{ translation().labels.confirm_password }}
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    id="password_confirmation"
                    v-model="password_confirmation"
                    :class="rtl('text-right')"
                    :disabled="processing"
                    aria-required="true"
                    autocomplete="new-password"
                    class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    required
                    type="password"
                  />
                </div>
              </div>
              <div v-if="showTermsCheckbox" class="mt-6">
                <div v-if="errors.confirm" class="text-red-500 text-sm mb-6">
                  {{ errors.confirm }}
                </div>
                <div class="flex">
                  <input
                    v-model="accept"
                    id="confirm"
                    name="confirm"
                    type="checkbox"
                    class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label
                    for="confirm"
                    class="ml-2 block text-sm leading-5 text-gray-900"
                  >
                    {{ translation().messages.terms.first }}
                    <a
                      @click.prevent="terms_modal_open = true"
                      class="text-indigo-500 underline"
                      href="#"
                      >{{ translation().messages.terms.second }}</a
                    >.
                  </label>
                </div>
              </div>
              <div class="mt-6">
                <span class="block w-full rounded-md shadow-sm">
                  <button
                    :disabled="processing"
                    class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out focus:ring-2 focus:ring-indigo-300"
                    test-ref="register"
                    type="submit"
                    @click.prevent="register"
                  >
                    <loader v-if="processing" class="w-5 h-5"></loader>
                    <span v-else>{{
                      translation().buttons_links.register
                    }}</span>
                  </button>
                </span>
              </div>
            </div>
          </form>
        </div>
      </template>
    </layout>
  </div>
  <terms-and-conditions-popup
    :open="terms_modal_open"
    @confirmed="onTermsConfirmed"
    @canceled="onTermsCancel"
  ></terms-and-conditions-popup>
</template>

<script>
import Layout from "@/components/Layout";
import Loader from "@/components/Loader";
import TermsAndConditionsPopup from "@/components/TermsAndConditionsPopup";

let responseData = {};

export default {
  beforeRouteEnter(to, from, next) {
    window.bus.$gates.completeRegistration(to, from, next, response => {
      responseData = response.data;
    });
  },
  components: {
    Layout,
    Loader,
    TermsAndConditionsPopup,
  },
  mounted() {
    this.email = responseData.user.email;
    this.service_types = responseData.user.service_types;
  },
  computed: {
    showTermsCheckbox() {
      return !this.service_types.includes("feMaths");
    },
  },
  data() {
    return {
      terms_modal_open: false,
      processing: false,
      email: "",
      email_confirmation: "",
      password: "",
      password_confirmation: "",
      accept: false,
      service_types: [],
      errors: {
        email: "",
        email_confirmation: "",
        password: "",
        accept: "",
        global: "",
        confirm: "",
      },
    };
  },
  methods: {
    formAction() {
      return this.$global.endpoint(
        `/api/${this.$APIVersion}/profile/${responseData.sha1EncryptedRef}/save-registration`,
      );
    },
    onTermsConfirmed() {
      this.accept = true;
      this.terms_modal_open = false;
    },
    onTermsCancel() {
      this.terms_modal_open = false;
    },
    register() {
      this.clearErrors();
      if (!this.$refs.form.checkValidity()) {
        return this.$refs.form.reportValidity();
      }
      this.processing = true;
      this.setXSRFToken(() => {
        this.$axios
          .post(this.formAction(), this.payload())
          .then(response => {
            this.$store.commit("setToken", response.data.token);
            this.$store.commit("setUser", response.data.user);
            this.$axios.defaults.headers.common["Authorization"] =
              "Bearer " + this.$store.state.token;
            this.processing = false;
            this.$router.push("/");
          })
          .catch(error => {
            this.$global.globalAXIOSErrorHandler(error);
            this.processing = false;
          });
      });
    },
    setXSRFToken(callback) {
      this.$axios
        .get(this.$global.endpoint("/sanctum/csrf-cookie"))
        .then(callback);
    },
    payload() {
      return {
        locale: this.$store.state.translatables.locale,
        email: this.email,
        email_confirmation: this.email_confirmation,
        password: this.password,
        password_confirmation: this.password_confirmation,
        confirm: this.showTermsCheckbox ? this.accept : true,
        device_name: window.WURFL
          ? window.WURFL.complete_device_name
          : "undefined",
      };
    },
    login() {
      this.$router.push("/login");
    },
    clearErrors() {
      this.errors = {
        global: "",
        email: "",
        email_confirmation: "",
        password: "",
        confirm: "",
      };
    },
  },
};
</script>
