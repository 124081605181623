<template>
  <teleport to="body">
    <div
      :class="ltr('right-0 pr-6') + rtl('left-0 pl-6')"
      class="top-0 fixed pt-4 sm:pr-6 max-w-sm w-full max-h-full overflow-y-auto z-50"
    >
      <transition-group
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-for="notification in notifications"
          :key="notification.id"
          class="flex items-end justify-center pointer-events-none sm:items-start sm:justify-end"
        >
          <div
            class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto mb-6"
          >
            <div class="rounded-lg shadow-xs overflow-hidden">
              <div class="p-4">
                <div
                  :class="rtl('flex-row-reverse text-right')"
                  class="flex items-start"
                >
                  <div class="flex-shrink-0">
                    <svg
                      v-if="notification.type === 'success'"
                      class="h-6 w-6 text-green-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <svg
                      v-if="notification.type === 'error'"
                      class="h-6 w-6 text-red-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <svg
                      v-if="notification.type === 'info'"
                      class="h-6 w-6 text-indigo-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div
                    :class="rtl('mr-3') + ltr('ml-3')"
                    class="w-0 flex-1 pt-0.5"
                  >
                    <p class="text-sm leading-5 font-medium text-gray-900">
                      {{ notification.heading }}
                    </p>
                    <p class="mt-1 text-sm leading-5 text-gray-500">
                      {{ notification.message }}
                    </p>
                  </div>
                  <div
                    v-if="notification.persistent"
                    class="ml-4 flex-shrink-0 flex"
                  >
                    <button
                      @click.prevent="remove(notification)"
                      class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                    >
                      <svg
                        class="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </teleport>
</template>

<script>
export default {
  computed: {
    notifications() {
      return this.$store.state.notifications.map(notification => {
        if (!notification.persistent && !notification.notified) {
          notification.notified = new Date();
          this.removeAfter(notification, 5000);
        } else if (
          !notification.persistent &&
          notification.notified &&
          new Date() - new Date(notification.notified) >= 5000
        ) {
          this.removeAfter(notification, 0);
        }
        return notification;
      });
    },
  },
  methods: {
    removeAfter(notification, interval) {
      setTimeout(() => this.remove(notification), interval);
    },
    remove(notification) {
      this.$store.commit("removeNotification", notification);
    },
  },
};
</script>
