<template>
  <div>
    <div v-if="should_show_dropdowns">
      <div class="rounded-md bg-blue-50 p-4 mb-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg
              class="h-5 w-5 text-blue-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm leading-5 text-blue-700">
              {{ translation().messages.candidate_import }}
            </p>
          </div>
        </div>
      </div>
      <div>
        <label
          for="series"
          class="block text-sm leading-5 font-medium text-gray-700"
          >Series</label
        >
        <select
          :disabled="exporting || loading"
          v-model="selected_series"
          @change="seriesChanged"
          id="series"
          class="mt-1 rounded-md block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
        >
          <option value="">Please select a series</option>
          <option
            v-for="_series in seriesList"
            :value="_series.code"
            :key="_series.code"
            >{{ _series.name }}
          </option>
        </select>
      </div>
      <div class="mt-4" v-if="booking_options.length">
        <label
          for="booking_options"
          class="block text-sm leading-5 font-medium text-gray-700"
          >Booking Options</label
        >
        <select
          :disabled="exporting || loading"
          v-model="selected_service"
          @change="serviceChanged"
          id="booking_options"
          class="mt-1 rounded-md block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
        >
          <option value="">Please select a booking option</option>
          <option
            v-for="option in booking_options"
            :value="option.id"
            :key="option.id"
            >{{ option.booking_service.name }} - {{ option.name }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="should_show_page_1" class="mt-4">
      <div class="sm:flex items-center sm:p-2 sm:rounded-md sm:bg-gray-100">
        <div class="w-full">
          <label class="sr-only">Search</label>
          <div class="flex rounded-md shadow-sm">
            <div class="relative focus-within:z-10">
              <select
                @change="filter"
                v-model="filters.series"
                :disabled="!selected_series || !selected_service"
                aria-label="Country"
                class="max-w-200 bg-white focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-4 pr-10 border-gray-200 border-r-1 text-gray-900 sm:text-sm rounded-none rounded-l-md"
              >
                <option value="">All Paper Series</option>
                <option
                  v-for="_series in assessmentSeriesList"
                  :value="_series.code"
                  :key="_series.code"
                  >{{ _series.name }}
                </option>
              </select>
            </div>
            <div class="relative focus-within:z-10">
                <select
                  @change="filter"
                  v-model="filters.subject"
                  :disabled="!selected_series || !selected_service"
                  aria-label="Subject"
                  class="max-w-200 bg-white focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-4 pr-10 border-gray-200 border-r-1 text-gray-900 sm:text-sm rounded-none"
                >
                  <option value="">All Subjects</option>
                  <option
                    v-for="_subject in subjects"
                    :value="_subject.code"
                    :key="_subject.code"
                    >{{ _subject.name }}</option
                  >
                </select>
            </div>
            <div
              class="relative flex items-stretch flex-grow focus-within:z-10"
            >
              <div
                class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
              >
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="h-5 w-5 text-gray-400"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                @keyup="filters.debounce"
                v-model="filters.search"
                :disabled="!selected_series || !selected_service"
                :class="{ 'opacity-75': !selected_series || !selected_service }"
                type="text"
                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none pl-10 sm:text-sm text-gray-900 border-gray-200"
                placeholder="Search assessments"
              />
            </div>
            <div class="relative focus-within:z-10">
              <select
                v-model="filters.sort"
                @change="filter"
                :disabled="!selected_series || !selected_service"
                class="bg-white focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-4 pr-10 border-gray-200 border-l-1 sm:text-sm text-gray-900 rounded-none rounded-r-md"
              >
                <option value="">Sort</option>
                <option value="titleAsc">Assessment title - ascending</option>
                <option value="titleDesc">Assessment title - descending</option>
                <option value="seriesAsc">Series - ascending</option>
                <option value="seriesDesc">Series - descending</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <span
          v-if="selected_service && accept_bookings"
          class="flex w-full sm:w-auto sm:inline-flex rounded-md shadow-sm mb-4 sm:mb-0 ml-auto mr-2"
        >
          <button
            @click.prevent="downloadTemplate"
            :disabled="exporting"
            type="button"
            class="flex w-full sm:w-auto sm:inline-flex items-center px-3 py-1.5 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150"
          >
            <svg
              class="-ml-1 mr-2 h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <loader v-if="exporting" class="w-5 text-white"></loader>
            <span v-else>Download Template</span>
          </button>
        </span>
        <span
          v-if="is_highstake && accept_bookings"
          class="flex w-full sm:w-auto sm:inline-flex rounded-md shadow-sm mb-4 sm:mb-0"
        >
          <button
            @click.prevent="showHighstakeImporter"
            type="button"
            class="flex w-full sm:w-auto sm:inline-flex items-center px-3 py-1.5 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150"
          >
            <svg
              class="-ml-1 mr-2 h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"
              ></path>
              <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
            </svg>
            <span class="ml-2">Import Bookings</span>
          </button>
        </span>
      </div>
      <div class="flex flex-col mt-4">
        <div
          class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <div
            class="align-middle inline-block min-w-full shadow-sm overflow-hidden border rounded-md border-gray-100"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Assessment Title
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Paper Series
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Subject Code
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Paper Code
                  </th>
                  <th
                    v-if="!is_highstake"
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!schedules.length" class="bg-white">
                  <td
                    colspan="20"
                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900"
                  >
                    Sorry, no results found
                  </td>
                </tr>
                <tr
                  v-for="schedule in schedules"
                  :key="schedule.id"
                  class="bg-white"
                >
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    {{ schedule.assessment.title }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    {{ schedule.assessment.series.name }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    <span
                      class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800"
                    >
                      {{ schedule.assessment.subject.code }}
                    </span>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    <span
                      class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800"
                    >
                      {{ schedule.assessment.code }}
                    </span>
                  </td>
                  <td
                    v-if="!is_highstake && accept_bookings"
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    <span class="inline-flex rounded-md shadow-sm">
                      <a
                        href="#"
                        @click.prevent="setImport(schedule)"
                        class="inline-flex items-center px-3 py-1.5 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150"
                      >
                        <svg
                          class="h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"
                          ></path>
                          <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
                        </svg>
                        <span class="ml-2">Import</span>
                      </a>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="selected_series && selected_service" class="mt-8">
            <pagination
              v-if="!loading"
              @paginate="paginate"
              :collection-name="'Assessments'"
              :prev-page="prevPage"
              :next-page="nextPage"
              :page-links="pageLinks"
              :to="pagination.showing.to"
              :from="pagination.showing.from"
              :of="pagination.showing.of"
              :current="pagination.current_page"
            >
            </pagination>
          </div>
        </div>
      </div>
    </div>

    <booking-csv-import
      @close="returnToListings"
      @imported="candidatesImported"
      @downloadTemplate="downloadMockTemplate"
      :schedule="schedule_import"
      :open="show_csv_importer"
      :exporting="exporting"
    />

    <booking-highstake-import
      v-if="show_highstake_importer"
      @close="returnToListings"
      @downloadTemplate="downloadHighStakeTemplate"
      :exporting="exporting"
      :seriesCode="selected_series"
      :bookingOptionId="selected_service"
    />

    <div
      v-if="loading"
      class="flex items-center justify-center h-16 border border-gray-100 bg-blue-50 rounded-md mt-4"
    >
      <loader class="w-5 text-blue-500"></loader>
      <span class="text-blue-500 ml-2 text-xs">loading data please wait</span>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import Pagination from "@/components/Pagination";
import _ from "lodash";
import fileDownload from "js-file-download";
import PaginationUtils from "../utils/PaginationUtils";
import BookingCsvImport from "@/components/BookingCsvImport";
import BookingHighstakeImport from "@/components/BookingHighstakeImport";

export default {
  components: {
    BookingHighstakeImport,
    BookingCsvImport,
    Loader,
    Pagination,
  },
  data() {
    return {
      show_highstake_importer: false,
      loading_from_saved_state: false,
      file: false,
      loading: true,
      seriesList: [],
      assessmentSeriesList: [],
      exporting: false,
      selected_series: "",
      selected_service: "",
      schedules: [],
      booking_options: [],
      subjects: [],
      schedule_import: null,
      importing: false,
      progress: 0,
      ...PaginationUtils.data,
      filters: {
        series: "",
        search: "",
        sort: "",
        subject: "",
        debounce: _.debounce(() => {
          this.filter();
        }, 500),
      },
    };
  },
  computed: {
    is_highstake() {
      return (
        this.selected_booking_option_object &&
        this.selected_booking_option_object.booking_service.service_type ===
          "highstake"
      );
    },
    selected_booking_option_object() {
      if (!this.selected_service) {
        return null;
      }

      return _.find(
        this.booking_options,
        option => option.id === this.selected_service,
      );
    },
    should_show_dropdowns() {
      return this.should_show_page_1;
    },
    should_show_page_1() {
      return !this.show_csv_importer && !this.show_highstake_importer;
    },
    show_csv_importer() {
      return this.schedule_import !== null && (this.importing || !this.loading);
    },
    accept_bookings() {
      return (
        this.selected_booking_option_object &&
        this.selected_booking_option_object.booking_service.accept_bookings
      );
    },
    ...PaginationUtils.computed,
  },
  mounted() {
    this.setDataFromQueryStringParams();
    this.$axios
      .get(this.$global.apiEndpoint("/bookings/series-list"))
      .then(response => {
        this.seriesList = response.data.series;
        this.loading = false;
      })
      .catch(error => {
        this.$global.globalAXIOSErrorHandler(error);
      });
  },
  methods: {
    setDataFromQueryStringParams() {
      let params = this.$global.getParamsFromQueryString();
      if (params.page)
        this.pagination.current_page = parseInt(
          decodeURIComponent(params.page),
        );
      if (params.query) this.filters.search = decodeURIComponent(params.query);
      if (params.series)
        this.filters.series = decodeURIComponent(params.series);
      if (params.order_by)
        this.filters.sort = decodeURIComponent(params.order_by);
      if (params.subject)
        this.filters.subject = decodeURIComponent(params.subject);
      if (params.drop_series) {
        this.loading_from_saved_state = true;
        this.selected_series = decodeURIComponent(params.drop_series);
        this.seriesChanged();
      }
      if (params.drop_booking) {
        this.loading_from_saved_state = true;
        this.selected_service = decodeURIComponent(params.drop_booking);
        this.serviceChanged();
      }
    },
    paginate(page_number) {
      this.filter(page_number);
    },
    returnToListings(refreshList = false) {
      this.loading = true;
      this.schedule_import = null;
      this.show_highstake_importer = false;
      if (refreshList) {
        this.serviceChanged();
      }
      this.loading = false;
    },
    candidatesImported() {
      this.importing = true;
      this.serviceChanged();
    },
    filter(page_number) {
      this.loading = true;
      this.pagination.current_page = page_number || 1;
      this.schedules = [];

      this.$axios
        .post(
          this.$global.apiEndpoint(
            `/dashboard/${this.selected_series}/${this.selected_service}/assessment-schedules`,
          ),
          {
            page: this.pagination.current_page,
            title: this.filters.search,
            assessmentSeries: this.filters.series,
            order_by: this.filters.sort,
            assessmentSubject: this.filters.subject
          },
        )
        .then(response => {
          this.loading_from_saved_state = false;
          this.schedules = response.data.assessment_schedules.data;
          this.pagination = this.$global.paginationData(
            response,
            "assessment_schedules",
          );
          this.republishQueryParameters();
          this.loading = false;
        });
    },
    republishQueryParameters() {
      if (history.pushState) {
        let newURL =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          `?page=${this.pagination.current_page}&query=${this.filters.search}&series=${this.filters.series}&order_by=${this.filters.sort}&drop_series=${this.selected_series}&drop_booking=${this.selected_service}&subject=${this.filters.subject}`;
        window.history.pushState({ path: newURL }, "", newURL);
      }
    },
    serviceChanged() {
      this.loading = true;
      this.schedules = [];

      if (!this.selected_service) {
        return (this.loading = false);
      }

      this.$axios
        .post(
          this.$global.apiEndpoint(
            `/dashboard/${this.selected_series}/${this.selected_service}/assessment-schedules`,
          ),
          {
            page: this.pagination.current_page,
            title: this.filters.search,
            series: this.filters.series,
            order_by: this.filters.sort,
            assessmentSeries: this.filters.series,
            assessmentSubject: this.filters.subject
          },
        )
        .then(response => {
          this.schedules = response.data.assessment_schedules.data;
          this.subjects = response.data.subjects;
          this.assessmentSeriesList = response.data.series;

          this.pagination = this.$global.paginationData(
            response,
            "assessment_schedules",
          );
          this.republishQueryParameters();
          if (this.schedule_import) {
            this.schedule_import = this.schedules.find(
              schedule => schedule.id === this.schedule_import.id,
            );
          }
          this.loading = false;
          this.importing = false;
          if (!this.schedules.length && !this.loading_from_saved_state) {
            this.$global.error(
              "No schedules have been created for this series and service.",
            );
          }
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error, false);
          this.importing = false;
        });
    },
    seriesChanged() {
      this.loading = true;
      this.schedules = [];
      this.booking_options = [];
      this.selected_service = "";

      if (!this.selected_series) {
        return (this.loading = false);
      }

      this.$axios
        .post(
          this.$global.apiEndpoint(
            `/dashboard/${this.selected_series}/booking-options`,
          ),
        )
        .then(response => {
          this.booking_options = response.data.booking_options;
          this.loading = false;
          if (!this.booking_options.length && !this.loading_from_saved_state) {
            this.$global.error(
              "No booking options found for this series for your organisation.",
            );
          }

          if (this.booking_options.length === 1) {
            this.selected_service = this.booking_options[0].id;
            this.serviceChanged();
          }
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error);
        });
    },
    downloadTemplate() {
      if (!this.selected_service) {
        return;
      }

      if (
        this.selected_booking_option_object.booking_service.service_type ===
        "highstake"
      ) {
        this.downloadHighStakeTemplate();
      } else {
        this.downloadMockTemplate();
      }
    },
    downloadHighStakeTemplate() {
      this.exporting = true;
      this.$axios
        .post(
          this.$global.apiEndpoint(`/dashboard/download-highstakes-template`),
          null,
          { responseType: "arraybuffer" },
        )
        .then(response => {
          this.exporting = false;
          fileDownload(
            response.data,
            "GCEGCSEBulkEntriesTemplate.xlt",
            "application/xlt",
          );
        });
    },
    downloadMockTemplate() {
      this.exporting = true;
      this.$axios
        .post(this.$global.apiEndpoint(`/dashboard/download-csv-template`))
        .then(response => {
          this.exporting = false;
          fileDownload(response.data, `mock-candidate-list.csv`,"text/csv;charset=utf-8","\uFEFF");
        });
    },
    setImport(schedule) {
      this.loading = true;
      this.loading = false;
      this.schedule_import = schedule;
    },
    showHighstakeImporter() {
      this.schedule_import = null;
      this.show_highstake_importer = true;
    },
  },
};
</script>
