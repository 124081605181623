<template>
  <router-view />
  <page-loader></page-loader>
</template>

<script>
import PageLoader from "@/components/PageLoader";

export default {
  components: {
    PageLoader,
  },
  beforeMount() {
    this.$gates.app(this).$global.app(this);
  },
  mounted() {
    this.$axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.$store.state.token;
    this.$global.translatables(
      this.$store.state.translatables
        ? this.$store.state.translatables.locale
        : "",
      () => {
        this.$moment.locale(this.$store.state.translatables.locale);
      },
    );
  },
};
</script>
