<template>
  <popup :open="open" :title="'Confirm Booking Deletion'" @cancel="close">
    <template v-slot:icon>
      <div
        class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100"
      >
        <svg
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          ></path>
        </svg>
      </div>
    </template>
    <template v-slot:content>
      <div class="text-left mt-8">
        <div class="rounded-md bg-yellow-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg
                class="h-5 w-5 text-yellow-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm leading-5 font-medium text-yellow-800">
                Attention needed
              </h3>
              <div class="mt-2 text-sm leading-5 text-yellow-700">
                <div v-if="hasBookingWithSubjectOption" class="mb-2">
                  A selected booking includes multiple components, and
                  components cannot be deleted individually. Proceeding with the
                  deletion will remove the candidate from all components in
                  their Subject Option.
                </div>
                <div>
                  Please confirm that you would like to delete the selected
                  booking<span v-if="plural">s</span>. This action cannot be
                  undone unless the candidate is re-booked onto the attached
                  schedule
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div
        class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
      >
        <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
          <button
            :disabled="processing"
            @click.prevent="deleteBooking"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            <loader v-if="processing" class="w-5 h-5"></loader>
            <span v-else>Yes, delete booking<span v-if="plural">s</span></span>
          </button>
        </span>
        <span
          class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1"
        >
          <button
            :disabled="processing"
            @click.prevent="close"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Cancel
          </button>
        </span>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from "@/components/Popup";
import Loader from "@/components/Loader";

export default {
  emits: ["deleted", "close"],
  props: {
    bookings: {
      type: Array,
      default: () => {
        return [];
      },
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasBookingWithSubjectOption() {
      return !!this.bookings.find(booking => booking.subject_option !== null);
    },
    plural() {
      return this.bookings.length > 1;
    },
  },
  data() {
    return {
      processing: false,
    };
  },
  components: {
    Popup,
    Loader,
  },
  methods: {
    deleteBooking() {
      this.processing = true;
      let ids = this.bookings.map(booking => booking.id);
      this.$axios
        .post(this.$global.apiEndpoint(`/bookings/actions`), {
          action: "delete",
          ids,
        })
        .then(response => {
          this.$global.success(response.data.message);
          this.$emit("deleted", response.data.deleted);
          this.processing = false;
          this.close();
        })
        .catch(error => {
          this.processing = false;
          this.$global.globalAXIOSErrorHandler(error);
        });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
