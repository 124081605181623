<template>
  <div v-if="open && user" class="border-b border-gray-700 md:hidden">
    <div class="pt-4 pb-3 border-t border-gray-700">
      <div
        :class="rtl('flex-row-reverse space-x-reverse')"
        class="flex items-center px-5 space-x-3"
      >
        <div class="flex-shrink-0">
          <img
            aria-label="Gravitar Icon"
            class="h-10 w-10 rounded-full"
            :src="icon"
            alt="Gravitar Icon"
          />
        </div>
        <div :class="rtl('text-right')" class="space-y-1">
          <div
            aria-label="Your full name"
            class="text-base font-medium leading-none text-white"
          >
            {{ user.full_name }}
          </div>
          <div
            aria-label="Your username"
            class="text-sm font-medium leading-none text-gray-400"
          >
            {{ user.username }}
          </div>
        </div>
      </div>
      <div
        v-if="user && !iframe"
        :class="rtl('text-right')"
        class="mt-3 px-2 space-y-1"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu"
      >
        <router-link
          role="link"
          aria-label="View dashboard"
          id="dashboard-link-mobile"
          class="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
          exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
          :to="{ name: 'Dashboard' }"
          >{{ translation.buttons_links.dashboard }}</router-link
        >
        <router-link
          role="link"
          aria-label="View bookings"
          id="bookings-link-mobile"
          class="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
          exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
          :to="{ name: 'Bookings' }"
          >{{ translation.buttons_links.bookings }}</router-link
        >
        <router-link
          role="link"
          aria-label="View invigilators"
          id="invigilators-link-mobile"
          class="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
          exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
          :to="{ name: 'Invigilators' }"
          >{{ translation.buttons_links.invigilators }}</router-link
        >
        <router-link
          role="link"
          aria-label="View scripts"
          id="scripts-link-mobile"
          class="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
          exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
          :to="{ name: 'Scripts' }"
          >Access to Scripts</router-link
        >
        <router-link
          v-if="showMarkSubmission()"
          role="link"
          aria-label="Mark submission"
          id="marks-link"
          class="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
          exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
          :to="{ name: 'Marks' }"
          >Mark Submission</router-link
        >
        <a
          aria-label="Log out of this application"
          @click.prevent="logout"
          href="#"
          class="logout block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
          role="menuitem"
          >{{ translation.buttons_links.sign_out }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import gravatar from "gravatar";

export default {
  props: {
    open: {
      default: false,
      type: Boolean,
    },
    iframe: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    translation() {
      return this.$store.state.translatables.translation;
    },
    user() {
      return this.$store.state.user;
    },
    icon() {
      return gravatar.url(this.user.email);
    },
  },
  methods: {
    showMarkSubmission() {
      if (!this.user.service_types) {
        return false;
      }

      const hasMockOrFeMaths =
        this.user.service_types.includes("mock") ||
        this.user.service_types.includes("feMaths");
      const hasHighStake = this.user.service_types.includes("highstake");

      return !hasMockOrFeMaths || (hasHighStake && hasMockOrFeMaths);
    },
    logout() {
      this.$emit("logout", true);
    },
  },
};
</script>
