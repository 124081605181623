<template>
  <layout>
    <template v-slot:contents>
      <div>
        <label
          for="series"
          class="block text-sm leading-5 font-medium text-gray-700"
          >Series</label
        >
        <select
          :disabled="loading"
          v-model="selected_series"
          id="series"
          class="mt-1 rounded-md block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
        >
          <option value="">Please select a series</option>
          <option
            v-for="series in series_list"
            :value="series.code"
            :key="series.code"
            >{{ series.name }}</option
          >
        </select>
      </div>
      <div class="mt-4" v-if="booking_options.length">
        <label
          for="booking_options"
          class="block text-sm leading-5 font-medium text-gray-700"
          >Booking Options</label
        >
        <select
          :disabled="loading"
          v-model="selected_option"
          id="booking_options"
          class="mt-1 rounded-md block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
        >
          <option value="">Please select a booking option</option>
          <option
            v-for="option in booking_options"
            :value="option.id"
            :key="option.id"
            >{{ option.booking_service.name }} - {{ option.name }}</option
          >
        </select>
      </div>
      <div
        v-if="loading"
        class="flex items-center justify-center h-16 border border-gray-100 bg-blue-50 rounded-md mt-4"
      >
        <loader class="w-5 text-blue-500"></loader>
        <span class="text-blue-500 ml-2 text-xs">loading data please wait</span>
      </div>

      <div class="flex flex-col mt-4">
        <div
          class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <div
            class="align-middle inline-block min-w-full shadow-sm overflow-hidden border rounded-md border-gray-100"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Assessment Title
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Series
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Subject Code
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Paper Code
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!loading && !schedules.length" class="bg-white">
                  <td
                    colspan="20"
                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900"
                  >
                    Sorry, no results found
                  </td>
                </tr>
                <tr
                  v-for="schedule in schedules"
                  :key="schedule.id"
                  class="bg-white"
                >
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    {{ schedule.assessment.title }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    {{ schedule.assessment.series.name }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    <span
                      class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800"
                    >
                      {{ schedule.assessment.subject.code }}
                    </span>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    <span
                      class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800"
                    >
                      {{ schedule.assessment.code }}
                    </span>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
                  >
                    <span class="inline-flex rounded-md shadow-sm">
                      <a
                        href="#"
                        @click.prevent="showSelectCandidatesModal(schedule)"
                        class="inline-flex items-center px-3 py-1.5 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150"
                      >
                        View Candidates
                      </a>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="selected_series && selected_option" class="mt-8">
            <pagination
              v-if="!loading"
              @paginate="paginate"
              :collection-name="'Assessments'"
              :prev-page="prevPage"
              :next-page="nextPage"
              :page-links="pageLinks"
              :to="pagination.showing.to"
              :from="pagination.showing.from"
              :of="pagination.showing.of"
              :current="pagination.current_page"
            >
            </pagination>
          </div>
        </div>
      </div>

      <candidate-selector-modal
        @close="popups.select_candidates = { open: false, scheduleId: null }"
        @review="reviewScript"
        :open="popups.select_candidates.open"
        :schedule-id="popups.select_candidates.scheduleId"
      />
      <review-learnosity-application
        @close="
          popups.review_learnosity_assessment = { open: false, session: null }
        "
        :open="popups.review_learnosity_assessment.open"
        :session="popups.review_learnosity_assessment.session"
      />
      <review-pdf-script
        @close="popups.review_pdf_assessment = { open: false, session: null }"
        :open="popups.review_pdf_assessment.open"
        :session="popups.review_pdf_assessment.session"
      />
    </template>
  </layout>
</template>

<script>
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import CandidateSelectorModal from "../components/CandidateSelectorModal";
import Pagination from "../components/Pagination";
import PaginationUtils from "../utils/PaginationUtils";
import ReviewLearnosityApplication from "../components/ReviewLearnosityApplication";
import ReviewPdfScript from "@/components/ReviewPdfScript";

export default {
  components: {
    ReviewPdfScript,
    ReviewLearnosityApplication,
    Pagination,
    CandidateSelectorModal,
    Layout,
    Loader
  },
  computed: {
    ...PaginationUtils.computed
  },
  data() {
    return {
      loading: false,
      booking_options: [],
      series_list: [],
      selected_series: null,
      selected_option: null,
      schedules: [],
      popups: {
        select_candidates: {
          open: false,
          scheduleId: null
        },
        review_learnosity_assessment: {
          open: false,
          session: null
        },
        review_pdf_assessment: {
          open: false,
          session: null
        }
      },
      ...PaginationUtils.data
    };
  },
  watch: {
    selected_series: function(newSeries, oldSeries) {
      this.booking_options = [];
      this.selected_option = null;
      this.schedules = [];

      if (newSeries && newSeries.trim() !== "" && newSeries !== oldSeries) {
        this.getBookingOptionsList();
      }
    },
    selected_option() {
      if (this.selected_option === null || this.selected_option === "") {
        this.schedules = [];
      } else {
        this.getAssessmentSchedules();
      }
    }
  },
  mounted() {
    this.setDataFromQueryStringParams();
    this.getSeriesList();
  },
  methods: {
    reviewScript(session) {
      session.media === "paper"
        ? this.showPdfReviewModal(session)
        : this.showLearnosityReviewModal(session);
    },
    showPdfReviewModal(session) {
      this.popups.review_pdf_assessment.open = true;
      this.popups.review_pdf_assessment.session = session;
    },
    showLearnosityReviewModal(session) {
      this.popups.review_learnosity_assessment.open = true;
      this.popups.review_learnosity_assessment.session = session;
    },
    setDataFromQueryStringParams() {
      const params = this.$global.getParamsFromQueryString();

      if (params.page)
        this.pagination.current_page = parseInt(
          decodeURIComponent(params.page)
        );
      if (params.drop_series)
        this.selected_series = decodeURIComponent(params.drop_series);
      if (params.drop_option)
        this.selected_option = decodeURIComponent(params.drop_option);
    },
    getAssessmentSchedules(page_number) {
      this.schedules = [];
      this.loading = true;
      this.pagination.current_page = page_number || 1;

      this.$axios
        .post(
          this.$global.apiEndpoint(
            `/dashboard/${this.selected_series}/${this.selected_option}/assessment-schedules`
          ),
          { page: this.pagination.current_page }
        )
        .then(response => {
          this.schedules = response.data.assessment_schedules.data;
          this.pagination = this.$global.paginationData(
            response,
            "assessment_schedules"
          );
          this.republishQueryParameters();
          if (!this.schedules.length) {
            this.$global.error(
              "No schedules have been created for this series and service."
            );
          }
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error, false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    paginate(page_number) {
      this.getAssessmentSchedules(page_number);
    },
    showSelectCandidatesModal(schedule) {
      this.popups.select_candidates.scheduleId = schedule.id;
      this.popups.select_candidates.open = true;
    },
    getBookingOptionsList() {
      this.loading = true;
      this.booking_options = [];

      this.$axios
        .post(
          this.$global.apiEndpoint(
            `/scripts/series/${this.selected_series}/booking-options`
          )
        )
        .then(response => {
          if (!response.data.booking_options.length) {
            this.$global.error(
              "No booking options found for this series for your organisation."
            );
          } else {
            this.booking_options = response.data.booking_options;

            if (this.booking_options.length === 1) {
              this.selected_option = this.booking_options[0].id;
            }
          }
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    republishQueryParameters() {
      if (history.pushState) {
        let newURL =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          `?page=${this.pagination.current_page}` +
          `&drop_series=${this.selected_series}` +
          `&drop_option=${this.selected_option}`;
        window.history.pushState({ path: newURL }, "", newURL);
      }
    },
    getSeriesList() {
      this.loading = true;

      this.$axios
        .get(this.$global.apiEndpoint("/scripts/series-list"))
        .then(response => {
          this.series_list = response.data.series;
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
