<template>
  <div :class="rtl('mr-3') + ltr('ml-3')" ref="languages_menu" class="relative">
    <button
      aria-label="Language switcher"
      test-ref="lang-button"
      @click.prevent="open = !open"
      class="p-1 border-2 border-transparent text-gray-300 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
    >
      <svg
        aria-hidden="true"
        class="w-6 h-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </button>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-show="open"
        :class="
          rtl('origin-top-left left-0 text-right') +
            ltr('origin-top-right right-0')
        "
        class="origin-top-right absolute mt-2 w-48 rounded-md shadow-lg"
      >
        <div role="navigation" class="py-1 rounded-md bg-white shadow-xs">
          <a
            role="menuitem"
            :aria-selected="isSelected(locale) ? 'true' : 'false'"
            :aria-label="`Set language to ${language}`"
            v-for="(language, locale) in locales"
            :key="locale"
            @click.prevent="changeLocale(locale)"
            :test-ref="`${locale}-locale`"
            href="#"
            :class="itemClass(locale)"
            class="logout-button flex items-center px-4 py-2 text-sm"
          >
            <div
              aria-hidden="true"
              :class="rtl('ml-2') + ltr('mr-2')"
              class="w-5"
            >
              <svg
                v-if="locale === 'fr'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 3 2"
              >
                <path fill="#ED2939" d="M0 0h3v2H0z" />
                <path fill="#fff" d="M0 0h2v2H0z" />
                <path fill="#002395" d="M0 0h1v2H0z" />
              </svg>
              <svg
                v-if="locale === 'en'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 60 30"
              >
                <clipPath>
                  <path d="M0 0v30h60V0z" />
                </clipPath>
                <clipPath>
                  <path d="M30 15h30v15zv15H0zH0V0zV0h30z" />
                </clipPath>
                <g clip-path="url(#a)">
                  <path d="M0 0v30h60V0z" fill="#012169" />
                  <path
                    d="M0 0l60 30m0-30L0 30"
                    stroke="#fff"
                    stroke-width="6"
                  />
                  <path
                    d="M0 0l60 30m0-30L0 30"
                    clip-path="url(#b)"
                    stroke="#C8102E"
                    stroke-width="4"
                  />
                  <path d="M30 0v30M0 15h60" stroke="#fff" stroke-width="10" />
                  <path
                    d="M30 0v30M0 15h60"
                    stroke="#C8102E"
                    stroke-width="6"
                  />
                </g>
              </svg>
              <svg
                v-if="locale === 'ar'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 480"
              >
                <path fill="#00732f" d="M0 0h640v160H0z" />
                <path fill="#fff" d="M0 160h640v160H0z" />
                <path d="M0 320h640v160H0z" />
                <path fill="red" d="M0 0h220v480H0z" />
              </svg>
            </div>
            <span>{{ language }}</span>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    document.addEventListener("click", this.binding);
  },
  computed: {
    translation() {
      return this.$store.state.translatables.translation;
    },
    locales() {
      return this.$store.state.translatables.languages;
    },
  },
  methods: {
    itemClass(locale) {
      let classes = this.rtl("flex-row-reverse");
      if (this.isSelected(locale)) {
        classes += " bg-gray-800 text-white";
      } else {
        classes += " text-gray-700 hover:bg-gray-100";
      }

      return classes;
    },
    isSelected(locale) {
      return locale === this.$store.state.translatables.locale;
    },
    binding(evt) {
      if (
        this.$refs.languages_menu &&
        !this.$refs.languages_menu.contains(evt.target)
      ) {
        this.open = false;
      }
    },
    changeLocale(locale) {
      this.$global.pageIsLoading();
      this.$global.translatables(locale, () => {
        this.$moment.locale(locale);
        this.$global.pageIsNotLoading();
      });
      this.open = false;
    },
  },
};
</script>
