export default {
  data: {
    pagination: {
      showing: {
        from: 0,
        to: 0,
        of: 0,
      },
      current_page: 1,
      last_page: 1,
    },
  },
  computed: {
    prevPage() {
      return this.pagination.current_page - 1;
    },
    nextPage() {
      return this.pagination.current_page + 1 >
        parseInt(this.pagination.last_page)
        ? 0
        : this.pagination.current_page + 1;
    },
    pageLinks() {
      let links = [];
      for (let i = 1; i <= this.pagination.last_page; i++) {
        links.push(i);
      }
      return links;
    },
  },
};
