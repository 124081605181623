<template>
  <section>
    <div
        aria-label="Your full name"
        v-if="user"
        class="text-white mr-4 text-sm"
    >
      <!-- if has_impersonate_centre is true user can switch the organisation  -->
      <template v-if="currUser.has_impersonate_centre">
        <a class="text-[#2980b9] underline cursor-pointer" @click.prevent="openUpdateOrganisation" v-if="user.organisation_external_id">
          [{{ user.organisation_external_id }}]
        </a>
      </template>
      <span v-else>
        [{{ user.organisation_external_id }}]
      </span>

      {{user.firstName}} {{user.lastName}}
    </div>


    <popup
        :open="open"
        :title="title"
    >
      <template v-slot:icon>
        <div
            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100"
        >
          <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
          >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </template>

      <template v-slot:content>
        <loader v-if="loading" class="w-5 h-5 change-centre-popup__loader"></loader>
        <div v-else class="text-start">

          <label for="org" class="mb-2 block">User's Organisation</label>
          <v-select
              id="org"
              :clearable="false"
              :filterable="true"
              :filter="customFilter"
              class="block w-full"
              v-model="selectedOrg"
              :reduce="ele => ele.id"
              label="name"
              :options="organisations"
          >
            <template v-slot:option="option">
              <!-- Customize label here -->
              {{ option.name }} [{{ option.external_id }}]
            </template>
            <template v-slot:selected-option="option">
              {{ option.name }} [{{ option.external_id }}]
            </template>
          </v-select>
        </div>
      </template>

      <template v-slot:footer>
        <div
            class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
        >
        <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
          <button
              :disabled="loading"
              @click.prevent="submit"
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            <loader v-if="loading" class="w-5 h-5"></loader>
            <span v-else>
              Change User's organisation
            </span>
          </button>
        </span>
          <span
              class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1"
          >
          <button
              @click.prevent="close"
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Cancel
          </button>
        </span>
        </div>
      </template>
    </popup>
  </section>
</template>
<script>
import Popup from "@/components/Popup.vue";
import Loader from "@/components/Loader.vue";
import vSelect from 'vue-select';
export default {
  components: {Loader, Popup, vSelect},
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      title: `Change User's Organisation`,
      open: false,
      search: '',
      selectedOrg: null,
      organisations: []
    }
  },
  computed: {
    currUser() {
      return this.$store.state.user;
    },
    filteredOrganisations() {
      return this.organisations.filter(org => {
        return org.name.toLowerCase().includes(this.search.toLowerCase()) ||
            org.external_id.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  mounted() {
    this.selectedOrg = this.currUser?.organisation_id;
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        await this.$axios.patch(this.$global.apiEndpoint(`/bookings/update-user-organisation/${this.selectedOrg}`));
        this.$store.commit("setUser", {
          ...this.currUser,
          organisation_id: this.selectedOrg,
          organisation_external_id: this.organisations.find(ele => +ele.id === +this.selectedOrg)?.external_id
        });
        this.close();
        this.$global.success("Organisation changed successfully for that user");
        window.location.reload();
      } catch(error) {
        this.$global.globalAXIOSErrorHandler(error, false);
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.open = false;
    },
    openUpdateOrganisation() {
      this.open = true;
      // cache organisations
      if(this.organisations.length === 0) {
        this.getAllOrganisations();
      }
    },
    async getAllOrganisations() {
      this.loading = true;
      try {
        const response = await this.$axios.get(this.$global.apiEndpoint(`/bookings/organisations`));
        this.organisations = response.data.organisations;
        this.filteredOrganisations = [...this.organisations];
      } catch(error) {
        this.$global.globalAXIOSErrorHandler(error, false);
      } finally {
        this.loading = false;
      }
    },
    customFilter(orgs, queryText) {
      // Customize this function based on your needs
      return orgs.filter(ele => {
        return ele.name.toLowerCase().includes(queryText.toLowerCase()) ||
            ele.external_id.toString().includes(queryText);
      })
    },
  }
}
</script>

<style>
.text-start {
  text-align: start;
}
.change-centre-popup__loader {
  margin: auto !important;
}
#vs2__listbox {
  position: absolute;
  z-index: 9999 !important;
}
.vs__dropdown-menu {
  z-index: 9999 !important;
}


.vs__dropdown-toggle {
  height: 47px;
}
.vs__search, .vs__search:focus {
  width: 100% !important;
  max-width: 100% !important;
}
.vs__search {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: -1;
}

</style>