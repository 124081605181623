<template>
  <popup
    :open="open"
    :title="'Edit Candidate'"
    @cancel="close"
    @state-change="stateChange"
  >
    <template v-slot:icon>
      <div
        class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100"
      >
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
    </template>
    <template v-slot:content>
      <div class="text-left mt-6">
        <label class="block text-sm font-medium leading-5 text-gray-700"
          >Candidate ID</label
        >
        <div class="mt-1 relative rounded-md shadow-sm">
          <div
            class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          >
            <svg
              class="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
              <path
                fill="#fff"
                d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
              />
            </svg>
          </div>
          <input
            v-model="values.candidate_id"
            id="full_name"
            :disabled="true"
            type="text"
            class="block w-full pl-10 sm:text-sm sm:leading-5 rounded-md border-gray-300"
            placeholder="John Doe"
          />
        </div>
      </div>
      <div class="text-left mt-6">
        <label
          for="forename"
          class="block text-sm font-medium leading-5 text-gray-700"
          >Forename</label
        >
        <div class="mt-1 relative rounded-md shadow-sm">
          <div
            class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          >
            <svg
              class="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <input
            v-model="values.forename"
            id="forename"
            :disabled="processing"
            type="text"
            class="block w-full pl-10 sm:text-sm sm:leading-5 rounded-md border-gray-300"
            placeholder="John Doe"
          />
        </div>
      </div>
      <div class="text-left mt-6">
        <label
          for="surname"
          class="block text-sm font-medium leading-5 text-gray-700"
          >Surname</label
        >
        <div class="mt-1 relative rounded-md shadow-sm">
          <div
            class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          >
            <svg
              class="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <input
            v-model="values.surname"
            id="surname"
            :disabled="processing"
            type="text"
            class="block w-full pl-10 sm:text-sm sm:leading-5 rounded-md border-gray-300"
            placeholder="John Doe"
          />
        </div>
      </div>
      <div class="text-left mt-6">
        <label
          for="gender"
          class="block text-sm font-medium leading-5 text-gray-700"
          >Gender</label
        >
        <div class="mt-1 relative rounded-md shadow-sm">
          <select
            v-model="values.gender"
            id="gender"
            class="block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md border-gray-300"
          >
            <option value="">--</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
      </div>
      <div class="text-left mt-6">
        <label
          for="dob"
          class="block text-sm font-medium leading-5 text-gray-700"
          >Date Of Birth</label
        >
        <div class="mt-1 relative rounded-md shadow-sm">
          <div
            class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          >
            <svg
              class="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </div>
          <input
            v-model="values.dob"
            id="dob"
            :disabled="processing"
            type="date"
            class="block w-full pl-10 sm:text-sm sm:leading-5 rounded-md border-gray-300"
            placeholder="john@example.com"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div
        class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
      >
        <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
          <button
            :disabled="processing"
            @click.prevent="saveCandidate"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            <loader v-if="processing" class="w-5 h-5"></loader>
            <span v-else>Save Candidate</span>
          </button>
        </span>
        <span
          class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1"
        >
          <button
            :disabled="processing"
            @click.prevent="close"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Close
          </button>
        </span>
      </div>
    </template>
  </popup>
</template>

<script>
import Loader from "@/components/Loader";
import Popup from "@/components/Popup";

export default {
  emits: ["close", "updated"],
  components: {
    Loader,
    Popup,
  },
  props: {
    booking: {
      type: Object,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      processing: false,
      values: {
        forename: "",
        surname: "",
        email: "",
        dob: "",
        gender: "",
        password: "",
        candidate_id: "",
      },
    };
  },
  methods: {
    stateChange(state) {
      this.$nextTick(() => {
        if (state) {
          this.values = {
            forename: this.booking.candidate.forename,
            surname: this.booking.candidate.surname,
            candidate_id: this.booking.candidate.external_id,
            email: this.booking.candidate.email,
            dob: this.$moment(this.booking.candidate.dob).format("YYYY-MM-DD"),
            gender: this.booking.candidate.gender,
            password: "",
          };
        }
      });
    },
    close() {
      this.$emit("close");
    },
    saveCandidate() {
      this.processing = true;
      this.$axios
        .patch(
          this.$global.apiEndpoint(`/bookings/${this.booking.id}/candidate`),
          this.values,
        )
        .then(response => {
          this.processing = false;
          this.$emit("updated", this.values);
          this.close();
          this.$global.success(response.data);
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error);
          this.processing = false;
        });
    },
  },
};
</script>
